<template>
  <vx-card>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <Grid
          ref="grid"
          :service="service"
          route_grid_path="/recording/grid"
          :hide_actions="true"
          :extraParams="gridParameters">

          <template v-slot:gridThead>
            <vs-th key="id" sort-key="id">ID</vs-th>
            <vs-th key="questionnaire" sort-key="questionnaire_name">{{ $t('Questionnaire') }}</vs-th>
            <vs-th key="start_at_formatted" sort-key="start_at_formatted">{{ $t('data-hora-inicio') }}</vs-th>
            <vs-th key="finish_at_formatted" sort-key="finish_at_formatted">{{ $t('data-hora-termino') }}</vs-th>
          </template>

          <template v-slot:gridActions>
            <vs-th key="actions">{{ $t('Actions') }}</vs-th>
          </template>

          <template v-slot:gridData="props">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
              <vs-td width="5%" key="id">
                {{ tr.questionnaire.id }}
              </vs-td>
              <vs-td width="40%" key="questionnaire">
                {{ !isEmpty(tr.questionnaire) ? tr.questionnaire.name : '---' }}
              </vs-td>
              <vs-td width="20%" key="start_at_formatted">
                {{tr.questionnaire.start_at_formatted}}
              </vs-td>
              <vs-td width="20%" key="finish_at_formatted">
                {{tr.questionnaire.finish_at_formatted}}
              </vs-td>
              <vs-td width="10%">
                <feather-icon v-if="tr.download_videos_url"
                  v-permission="'proctoring.recording.download'"
                  icon="DownloadIcon"
                  svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                  @click="downloadZipVideos(tr)"
                  v-tooltip="{
                    content: $t('proctoring.download_recording'),
                    delay: {show: 400, hide: 500}
                  }"/>
              </vs-td>
            </vs-tr>
          </template>
        </Grid>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ProctoringService from '@/services/api/ProctoringService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import FileDownload from 'js-file-download'

export default {
  components: { SelectSuggestion },

  data: () => ({
    service: null,
    questionnaire: null,
  }),

  watch: {
    questionnaire(val) {
      this.updateGrid()
    },
  },

  computed: {
    validFilters() {
      return this.questionnaire && this.questionnaire.id
    }
  },

  methods: {
    updateGrid() {
      if (this.validFilters) {
        this.grid().model.current_page = 0
        this.grid().fillCustomFilters(
          [
           {key:'questionnaire_id', value: this.questionnaire.id}]
        )
        this.grid().fetchGridData(false)
      }
    },
    grid() {
      return this.$refs['grid']
    },
    downloadZipVideos(data){
      const questionnaireId = data.questionnaire.id
      const fileName = data.download_videos_url
      const service = ProctoringService.build(this.$vs, `${process.env.VUE_APP_API_PROCTORING_BASE_URL}`)

      //Get temp link
      this.notifySuccess(this.$vs, this.$t('starting-download'))
      service.downloadRecordingLink(questionnaireId, fileName).then((data) => {
          const openTab = () => {this.$utils.browser.newTab(data.link)}
          openTab()
        },
        error => {
          this.notifyError(this.$vs, this.$t('erro-download'))
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )

  /*
      this.notifySuccess(this.$vs, this.$t('starting-download'))
      this.$vs.loading()
      service.downloadRecording(questionnaireId, fileName).then(data => {

        },
        error => {
          this.notifyError(this.$vs, this.$t('erro-download'))
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
      */
    }

  },

  beforeMount() {
    this.service = ProctoringService.build(this.$vs)
  }
}
</script>
