var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", [
    _c("div", { staticClass: "grid grid-cols-12 gap-4" }, [
      _c(
        "div",
        { staticClass: "col-span-12" },
        [
          _c("Grid", {
            ref: "grid",
            attrs: {
              service: _vm.service,
              route_grid_path: "/recording/grid",
              hide_actions: true,
              extraParams: _vm.gridParameters,
            },
            scopedSlots: _vm._u([
              {
                key: "gridThead",
                fn: function () {
                  return [
                    _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                      _vm._v("ID"),
                    ]),
                    _c(
                      "vs-th",
                      {
                        key: "questionnaire",
                        attrs: { "sort-key": "questionnaire_name" },
                      },
                      [_vm._v(_vm._s(_vm.$t("Questionnaire")))]
                    ),
                    _c(
                      "vs-th",
                      {
                        key: "start_at_formatted",
                        attrs: { "sort-key": "start_at_formatted" },
                      },
                      [_vm._v(_vm._s(_vm.$t("data-hora-inicio")))]
                    ),
                    _c(
                      "vs-th",
                      {
                        key: "finish_at_formatted",
                        attrs: { "sort-key": "finish_at_formatted" },
                      },
                      [_vm._v(_vm._s(_vm.$t("data-hora-termino")))]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridActions",
                fn: function () {
                  return [
                    _c("vs-th", { key: "actions" }, [
                      _vm._v(_vm._s(_vm.$t("Actions"))),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridData",
                fn: function (props) {
                  return _vm._l(props.data, function (tr, indextr) {
                    return _c(
                      "vs-tr",
                      { key: indextr, attrs: { data: tr } },
                      [
                        _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                          _vm._v(" " + _vm._s(tr.questionnaire.id) + " "),
                        ]),
                        _c(
                          "vs-td",
                          { key: "questionnaire", attrs: { width: "40%" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.isEmpty(tr.questionnaire)
                                    ? tr.questionnaire.name
                                    : "---"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            key: "start_at_formatted",
                            attrs: { width: "20%" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(tr.questionnaire.start_at_formatted) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            key: "finish_at_formatted",
                            attrs: { width: "20%" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(tr.questionnaire.finish_at_formatted) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          { attrs: { width: "10%" } },
                          [
                            tr.download_videos_url
                              ? _c("feather-icon", {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: "proctoring.recording.download",
                                      expression:
                                        "'proctoring.recording.download'",
                                    },
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: _vm.$t(
                                          "proctoring.download_recording"
                                        ),
                                        delay: { show: 400, hide: 500 },
                                      },
                                      expression:
                                        "{\n                  content: $t('proctoring.download_recording'),\n                  delay: {show: 400, hide: 500}\n                }",
                                    },
                                  ],
                                  attrs: {
                                    icon: "DownloadIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadZipVideos(tr)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }